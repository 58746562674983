import axios from 'axios';
import { setCurrentBrand } from '../ducks/brands';
import store from '../store';
import handleResponseError from './axiosInterceptors';

const endpoint = process.env.REACT_APP_ZORRO_URL;

const getHeaders = () => ({
  Authorization: `Bearer ${store.getState().auth?.token}`,
});

axios.defaults.headers.post['Content-Type'] = 'application/json';

// Add a response interceptor
if (window.location.pathname.indexOf('/login') === -1) {
  axios.interceptors.response.use((response) => response, handleResponseError);
}

const getBrand = () => {
  const currentState = store.getState();
  const { currentBrand } = currentState.brands;
  const defaultBrand = currentState.brands.brands[0];
  const localCurrentBrand = localStorage.getItem('v1_current-brand');

  if (!currentBrand) {
    store.dispatch(setCurrentBrand(localCurrentBrand || defaultBrand));

    return localCurrentBrand || defaultBrand;
  }

  return currentBrand;
};

export const get = async (url, config) =>
  axios.get(`${endpoint}/partner/brand/${getBrand()}${url}`, {
    headers: getHeaders(),
    ...config,
  });

export const getNoBrand = (url, config) =>
  axios.get(`${endpoint}${url}`, {
    headers: getHeaders(),
    ...config,
  });

export const post = (url, data, config) =>
  axios.post(`${endpoint}/partner/brand/${getBrand()}${url}`, data, {
    headers: getHeaders(),
    ...config,
  });

export const postNoBrand = (url, args) =>
  axios.post(`${endpoint}${url}`, args, {
    headers: getHeaders(),
  });

export const postNoAuth = (url, args) => axios.post(`${endpoint}${url}`, args);

export const patchNoBrand = (url, args) =>
  axios.patch(`${endpoint}${url}`, args, {
    headers: getHeaders(),
  });

export const deleteNoBrand = (url) =>
  axios.delete(`${endpoint}${url}`, {
    headers: getHeaders(),
  });

export const put = (url, data, config) =>
  axios.put(`${endpoint}/partner/brand/${getBrand()}${url}`, data, {
    headers: getHeaders(),
    ...config,
  });

export const putNoBrand = (url, args) =>
  axios.put(`${endpoint}${url}`, args, {
    headers: getHeaders(),
  });

export const patch = (url, data, config) =>
  axios.patch(`${endpoint}/partner/brand/${getBrand()}${url}`, data, {
    headers: getHeaders(),
    ...config,
  });
