import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, message } from 'antd';
import { useLocation } from 'react-router-dom';

import { setAuth } from '../ducks/auth';
import { setBrands, setCurrentBrand } from '../ducks/brands';
import LoginForm from '../components/LoginForm/LoginForm';
import { postNoAuth } from '../services/zorro';
import { initRefreshToken } from '../services/tokenRefresh';
import ARLogo from '../images/logo-ar.svg';

const Login = () => {
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(
    'Sorry, something went wrong. Please try again later.'
  );
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    // check to see if the user is trying to access a route that needs them to be authorized
    if (search.includes('?next=')) {
      message.error(
        'You must be logged in to view this page. Please login to continue.',
        0
      );
    }
  }, [search]);

  const handleSubmit = async (values) => {
    setIsAuthLoading(true);
    message.destroy();

    try {
      const res = await postNoAuth('/partner/auth/login', values);

      if (!res.data.brands) {
        setHasApiError(true);
        setApiErrorMessage(
          'Woops an error has occurred. Please contact Airtime Rewards quoting error code 1101.'
        );
      } else {
        dispatch(setAuth(res.data));
        initRefreshToken(res.data);
        dispatch(setBrands(res.data.brands));
        dispatch(setCurrentBrand(res.data.brands[0].id));
      }
    } catch (error) {
      setHasApiError(true);

      if (error?.response?.status === 401) {
        // user is unauthorized
        setApiErrorMessage('Invalid email/password combination');
      }
    }

    setIsAuthLoading(false);
  };

  const handleInputFocus = () => {
    setHasApiError(false);
  };

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={{ height: '100vh' }}
    >
      <Col span={24}>
        <img
          src={ARLogo}
          alt="airtime rewards"
          style={{ display: 'block', margin: '0 auto 50px', width: '250px' }}
        />
        <Card
          title="Login"
          style={{ width: '90%', maxWidth: '500px', margin: '0 auto' }}
        >
          <LoginForm
            handleInputFocus={handleInputFocus}
            handleSubmit={handleSubmit}
            hasApiError={hasApiError}
            apiErrorMessage={apiErrorMessage}
            authLoading={isAuthLoading}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
